import Vue from 'vue'
import Vuex from 'vuex'

import _ from 'lodash'

import VuexPersist from 'vuex-persist'
import localforage from 'localforage'

const modules = {}
const initialStates = {}

const files = require.context('.', false, /\.store\.js$/)
files.keys().forEach(key => {
  if (key === './index.js') return
  const storeKey = key.replace(/(\.\/|\.store\.js)/g, '')
  modules[storeKey] = files(key).default
  initialStates[storeKey] = modules[storeKey].state
})

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: localforage,
  modules: ['user', 'users', 'devices'],
  asyncStorage: true, // Ensure async is used
  restoreState: async (key, storage) => {
    try {
      const restoredState = JSON.parse(await storage.getItem(key))
      const mergedState = _.merge({}, initialStates, restoredState)

      return mergedState || {}
    } catch (error) {
      console.error('Error restoring state:', error)
      return {}
    }
  },
  saveState: async (key, state, storage) => {
    try {
      await storage.setItem(key, JSON.stringify(state))
    } catch (error) {
      console.error('Error saving state:', error)
    }
  }
})

const store = new Vuex.Store({
  modules,
  plugins: [vuexLocalStorage.plugin],
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    RESTORE_MUTATION (state, payload) {
      Object.assign(state, payload)
    }
  },
  actions: {
    async restoreState ({ commit }) {
      const restoredState = await vuexLocalStorage.restoreState('vuex', localforage)
      commit('RESTORE_MUTATION', restoredState)
    }
  }
})

// Ensure the state is restored before exporting the store
store.dispatch('restoreState')

export default store
