import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import './plugins/api'

import LineClamp from 'vue-line-clamp'
import Clipboard from 'v-clipboard'
import Snackbar from 'vuetify-snackbars'

import lodash from 'lodash'
import VueLodash from 'vue-lodash'

import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'

const oktaAuth = new OktaAuth({
  issuer: process.env.OKTA_DOMAIN,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`
})

Vue.use(OktaVue, { oktaAuth })

Vue.use(LineClamp, { importCss: true })
Vue.use(Clipboard)
Vue.use(Snackbar)

Vue.use(VueLodash, { lodash })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
