import Vue from 'vue'

import _ from 'lodash'

import { api } from '@/plugins/api'

const defaultState = () => {
  return {
    devices: {},
    fields: [],
    locationKey: 'NONE_NONE_NONE',
    locations: [
      { key: 'All', desc: 'All locations', value: 'ALL_LOCATIONS' },
      { key: 'VLN', desc: 'Vilnius', value: 'VLN' },
      { key: 'MTL', desc: 'Montreal', value: 'MTL' }
    ],
    deviceNoteHistory: {
      deviceId: null,
      allHistory: [],
      assigneeHistory: []
    },
    loading: {
      devices: false,
      deviceNoteHistory: false
    }
  }
}

const state = defaultState()

const mutations = {
  SET_DEVICES (state, payload) {
    state.devices = payload
  },
  SET_FIELDS (state, payload) {
    state.fields = payload
  },
  SET_LOCATION_KEY (state, payload) {
    state.locationKey = payload
  },
  SET_DEVICE_NOTE_HISTORY (state, payload) {
    state.deviceNoteHistory = payload
  },
  SET_LOADING (state, payload) {
    state.loading[payload.key] = payload.value
  },
  REPLACE_DEVICE (state, payload) {
    Vue.set(state.devices, payload.asset_tag, payload)
  }
}

const actions = {
  async fetchDevices ({ commit, state }, locationKey) {
    locationKey = locationKey || state.locationKey
    // store has not been initialized yet
    if (locationKey === 'NONE_NONE_NONE') {
      return
    }

    commit('SET_LOADING', { key: 'devices', value: true })
    try {
      const { data: devices } = await api.get(`/devices/${locationKey}`)
      commit('SET_DEVICES', _.keyBy(devices.data, 'asset_tag'))
    } catch (error) {
      console.error(error)
    }
    try {
      const { data: fields } = await api.get('/devices/fields')
      commit('SET_FIELDS', fields.data)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'devices', value: false })
  },
  async fetchDeviceNoteHistory ({ commit }, deviceId) {
    commit('SET_LOADING', { key: 'deviceNoteHistory', value: true })
    try {
      const { data: deviceNoteHistory } = await api.get(`/devices/notes-history/${deviceId}`)
      commit('SET_DEVICE_NOTE_HISTORY', {
        deviceId,
        allHistory: deviceNoteHistory.data.allHistory,
        assigneeHistory: deviceNoteHistory.data.assigneeHistory
      })
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'deviceNoteHistory', value: false })
  },
  replaceDevice ({ commit }, payload) {
    try {
      commit('REPLACE_DEVICE', payload)
    } catch (error) {
      console.error(error)
    }
  },
  setLocationKey ({ commit }, payload) {
    try {
      commit('SET_LOCATION_KEY', payload)
    } catch (error) {
      console.error(error)
    }
  }
}

const getters = {
  devicesArray: state => {
    return _.values(state.devices)
  },
  getDeviceByAssetTag: state => assetTag => {
    return state.devices[assetTag]
  },
  deviceNoteHistoryArray: state => {
    return state.deviceNoteHistory
  },
  locationKey: state => {
    return state.locationKey
  },
  getCurrentLocation: state => {
    if (state.locationKey === 'NONE_NONE_NONE') {
      return state.locations[0]
    }
    return state.locations.find(location => location.value === state.locationKey)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
