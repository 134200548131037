<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import Vue from 'vue'

import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  name: 'App',
  watch: {
    'authState.isAuthenticated': function (value) {
      this.handleUserData(value)
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('usbDevices', ['usbSupported'])
  },
  methods: {
    ...mapActions('user', ['LOGIN', 'LOGOUT']),
    ...mapActions('usbDevices', ['getUsbDevices']),
    async handleUserData (value) {
      if (value) {
        console.log('LOGIN')
        const user = await this.$auth.getUser()
        const { data: { data: isAdmin } } = await this.$api.get('/admin')
        this.LOGIN({ user, isAdmin })
      } else {
        setTimeout(
          () => {
            if (!this.authState.isAuthenticated) {
              console.log('LOGOUT')
              this.LOGOUT()
            }
          },
          1000
        )
      }
    }
  },
  created () {
    if (this.usbSupported()) {
      navigator.usb.onconnect = this.getUsbDevices
      navigator.usb.ondisconnect = this.getUsbDevices
      setInterval(this.getUsbDevices, 5000)
      this.getUsbDevices()
    }
  },
  mounted () {
    this.handleUserData(this.authState.isAuthenticated)
  }
})
</script>
