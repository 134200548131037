import _ from 'lodash'

import { api } from '@/plugins/api'

const defaultState = () => {
  return {
    users: {},
    userEmailToOktaIdMap: {},
    loading: {
      users: false
    }
  }
}

const state = defaultState()

const mutations = {
  SET_USERS (state, payload) {
    state.users = payload.users
    state.userEmailToOktaIdMap = payload.userEmailToOktaIdMap
  },
  SET_LOADING (state, payload) {
    state.loading[payload.key] = payload.value
  }
}

const actions = {
  async fetchUsers ({ commit }) {
    commit('SET_LOADING', { key: 'users', value: true })
    try {
      const { data: users } = await api.get('/users')
      const oktaIdToUserMap = _.keyBy(users.data, 'okta_id')
      const userEmailToOktaIdMap = _(oktaIdToUserMap).mapValues('email').invert().value()
      commit('SET_USERS', { users: oktaIdToUserMap, userEmailToOktaIdMap })
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'users', value: false })
  }
}

const getters = {
  usersArray: state => {
    return _.values(state.users)
  },
  getUserByOktaId: state => oktaId => {
    return state.users[oktaId]
  },
  getUserByEmail: state => email => {
    return state.users[state.userEmailToOktaIdMap[email]]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
