import _ from 'lodash'
import { api } from '@/plugins/api'

const defaultState = () => {
  return {
    usbSupported: () => {
      return 'usb' in window.navigator
    },
    usbDevices: []
  }
}

const state = defaultState()

const mutations = {
  SET_USB_DEVICES (state, payload) {
    state.usbDevices = payload
  }
}

const actions = {
  async getUsbDevices ({ commit, dispatch, state }) {
    try {
      const usbDevices = await navigator.usb.getDevices()
      const newDevices = _.differenceBy(usbDevices, state.usbDevices, 'serialNumber')
      for (const device of newDevices) {
        dispatch('assign', device)
      }
      commit('SET_USB_DEVICES', usbDevices)
    } catch (error) {
      console.error(error)
    }
  },
  async assign ({ dispatch, rootState }, device) {
    const currentDevice = _.find(
      rootState.devices.devices,
      item => {
        const serialNumberSafe = (item.serial_number || '').toLowerCase().replace(/[^a-z0-9]/gi, '')
        return serialNumberSafe === (device.serialNumber || '').toLowerCase().replace(/\0/g, '')
      }
    )
    if (currentDevice) {
      try {
        const { data: response } = await api.post('/device/assign', { asset_tag: currentDevice.asset_tag })
        dispatch('devices/replaceDevice', response.data, { root: true })
      } catch (error) {
        console.error(error)
      }
    }
  }
}

const getters = {
  usbDevicesSerialNumbers: state => {
    return state.usbDevices.map(device => device.serialNumber.toLowerCase().replace(/\0/g, ''))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
